<template>
  <nav
    class="elementor-nav-menu--main elementor-container elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-background e--animation-fade"
  >
    <ul class="elementor-nav-menu">
      <li
        v-for="item in menuList"
        :key="item.name"
        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home"
        :class="{active:curPath == item.href}"
      >
        <a @click="href(item.href)" class="elementor-item menu-link"
          >{{ $t(`${item.name}`) }}
          <i
            v-if="item.children && item.children.length > 0"
            class="iconfont icon-xiajiantou"
          ></i>
        </a>
        <ul
          v-if="item.children && item.children.length > 0"
          class="sub-menu elementor-nav-menu--dropdown"
        >
          <li
            v-for="subitem in item.children"
            :key="subitem.name"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-4312"
          >
            <a
              @click="href(subitem.href)"
              class="elementor-sub-item elementor-item-anchor menu-link"
              >{{ subitem.name
              }}<i
                v-if="subitem.children && subitem.children.length > 0"
                class="iconfont icon-youjiantou"
              ></i
            ></a>
            <ul
              v-if="subitem.children && subitem.children.length > 0"
              class="sub-menu1 elementor-nav-menu--dropdown"
            >
              <li
                v-for="menuitem in subitem.children"
                :key="menuitem.name"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-4316"
              >
                <a @click="htef(menuitem.href)" class="elementor-sub-item menu-link">{{
                  menuitem.name
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    <div class="elementor-column">
      <div class="elementor-widget-wrap">
        <div class="elementor-element">
          <div class="elementor-widget-container">
            <form class="elementor-search-form" role="search">
              <div class="elementor-search-form__container">
                <div class="input">
                  <input
                    placeholder="Search..."
                    class="elementor-search-form__input"
                    type="search"
                    name="s"
                    title="Search"
                    :v-model="searcValue"
                  />
                </div>
                <button
                  class="elementor-search-form__submit"
                  type="submit"
                  title="Search"
                  aria-label="Search"
                >
                  <i class="iconfont icon-search1"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          name: "menu.home",
          href: "/home",
        },
        {
          name: "menu.products",
          href: "/products",
          // children: [
          //   {
          //     name: "menu.about1",
          //     href: "/about",
          //   },
          //   {
          //     name: "menu.about2",
          //     href: "/about",
          //     children: [
          //       {
          //         name: "menu.111",
          //         href: "/111",
          //       },
          //     ],
          //   },
          // ],
        },
        // {
        //   name: "menu.catalog",
        //   href: "/catalog",
        // },
        {
          name: "menu.company",
          href: "/about",
        },

        {
          name: "menu.contact",
          href: "/contact",
        },
      ],
      searcValue: "",
      curPath:'/home'
    };
  },
  watch: {
    $route(n) {
      this.curPath = n.path
     
    },
  },
  components: {},

  computed: {},

  mounted() {},

  methods: {
    href(path){
      this.$router.push({path})
    }
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/menu.less");
</style>
