<template>
    <div class="gt_switcher_wrapper">
      <div class="gt_switcher notranslate">
        <div
          class="gt_option"
          :style="{
            height: optionShow ? '50px' :'0px',
          }"
        >
          <a
            v-for="i in langList"
            :key="i.name"
            :title="i.name"
            class="nturl"
            @click="selectLang(i)"
            ><img
              width="24"
              height="24"
              :alt="i.name"
              :src="require(`../assets/images/${i.img}.png`)"
            />
            {{ $t(`${i.name}`) }}</a
          >
        </div>
        <div class="gt_selected" @click="optionShow = !optionShow">
          <a :title="curLang.name" :class="{ open: optionShow }" class="nturl"
            ><img
              width="24"
              height="24"
              :alt="curLang.name"
              :src="require(`../assets/images/${curLang.img}.png`)"
            />
            {{ $t(`${curLang.name}`) }}</a
          >
        </div>
      </div>
    </div>
</template>

<script>
export default {
    data() {
    return {
      optionShow: false,
      langList: [
        {
          name: "zhlangName",
          img: "zh",
        },
        {
          name: "cnlangName",
          img: "en",
        },
      ],
      curLang: {
        name: "zhlangName",
        img: "zh",
      },
    };
  },
  mounted() {
    this.$i18n.locale == "zh"
      ? (this.curLang = this.langList[0])
      : (this.curLang = this.langList[1]);
  },
  methods: {
    selectLang(item) {
      this.curLang = item;
      window.localStorage.setItem('i18n',item.img)
      this.optionShow = false;
      this.$i18n.locale = item.img;
    },
    handelChangeLanguer() {
      if (this.$i18n.locale == "zh") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "zh";
      }
    },
  },
}
</script>

<style scoped lang="less">
.gt_switcher_wrapper {
  position: fixed;
  top: 0;
  right: 8%;
  z-index: 999999;
  .gt_switcher {
    font-family: Arial;
    font-size: 12pt;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    width: 173px;
    line-height: 0;
  }
  .gt_option {
    position: relative;
    z-index: 9998;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    background-color: #eee;
    // display: none;
    width: 171px;
    // max-height: 198px;
    // height: 0;
    box-sizing: content-box;
    // overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.5s ease-in-out;
  }

  .gt_selected {
    background: #fff linear-gradient(180deg, #efefef 0%, #fff 70%);
    position: relative;
    z-index: 9999;

    a::after {
      height: 24px;
      display: inline-block;
      position: absolute;
      right: 10px;
      width: 15px;
      background-position: 50%;
      background-size: 11px;
      background-image: url("../assets/svg/下载.svg");
      background-repeat: no-repeat;
      content: "" !important;
      transition: all 0.2s;
    }
    .open:after {
      transform: rotate(-180deg);
    }
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 12pt;
    box-sizing: content-box;
  }
}
</style>
