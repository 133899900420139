<template>
  <div>
    <div class="home_banner elementor-container">
      <a href="/">
        <img
          src="../assets/images/logo.png"
          alt=""
        />
          <img
          src="../assets/images/logo1.png"
          alt=""
        />
      </a>
      <div class="info">
        <nav v-for="i in infoData" :key="i.icon">
          <i class="" :class="['iconfont', i.icon]"></i>
          <div>
            <span>{{ $t(`${i.name}`) }}</span>
            <p>{{ $t(`${i.value}`) }}</p>
          </div>
        </nav>
      </div>
    </div>
    <menu-nav />
  </div>
</template>

<script>
import menuNav from "./menu.vue";
export default {
  data() {
    return {
      infoData: [
        {
          name: "phoneName",
          icon: "icon-dianhua",
          value: "phone",
        },
        {
          name: "timeName",
          icon: "icon-shijian",
          value: "timeValue",
        },
        {
          name: "emailName",
          icon: "icon-youxiang",
          value: "email",
        },
      ],
    };
  },

  components: { menuNav },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.home_banner {
  border-style: solid;
  border-width: 3px 0px 0px 0px;
  border-color: #f05523;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    // width: 200px;
    height: 100px;
  }
  .info {padding: 15px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    nav {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        border-width: 0px 1px 0px 1px;
        border-style: solid;
        border-color: #dddddd;
        i{
          font-size: 55px;
        }
      }
      padding: 10px;
      i {
        font-size: 40px;
        color: #f05523;
      }

      div {
        margin-left: 10px;
        text-align: left;
        color: #000;
        span {
          font-size: 16px;
        }
        p {
          color: #000000;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .home_banner {
    text-align: center;
    justify-content: center;
    .info{
      display: none;
    }
  }
}
</style>
