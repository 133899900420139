import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
import "./assets/css/index.less";
import "./assets/iconfont/iconfont.css";
import i18n from "./lang/i18n";

const app = createApp(App);

// 将i18n实例挂载到Vue应用程序上
app.use(ElementPlus);

app.use(router);
app.use(i18n);
app.mount("#app");