<template>
  <div class="footer">
    <div class="">
      <div class="footer_top">
        <div class="introduce elementor-container">
          <nav class="one">
            <div>
              <img src="../assets/images/logo1.png" alt="" />
              <img src="../assets/images/logo.png" alt="" />
            </div>
            <p>{{ $t("footer.introduce") }}</p>
          </nav>
          <nav class="two">
            <h3>{{ $t("footer.about.title") }}</h3>
            <a @click="href('about')">{{ $t("footer.about.a1") }}</a>
            <!-- <a @click="href()">{{ $t("footer.about.a2") }}</a> -->
            <a @click="href('products')">{{ $t("footer.about.a3") }}</a>
            <a @click="href('contact')">{{ $t("footer.about.a4") }}</a>
          </nav>
          <!-- <nav class="three">
            <h3>{{ $t("footer.help.title") }}</h3>
            <a @click="href()#">{{ $t("footer.help.a1") }}</a>
            <a @click="href()#">{{ $t("footer.help.a2") }}</a>
            <a @click="href()#">{{ $t("footer.help.a3") }}</a>
          </nav> -->
          <nav class="one four">
            <h3>{{ $t("footer.contact.title") }}</h3>
            <p>{{ $t("footer.contact.add") }}{{ $t("add") }}</p>
            <p>{{ $t("footer.contact.phone") }}{{ $t("phone") }}</p>
            <p>
              <a href="mailto:funaikangsafety@yeah.net"
                >{{ $t("footer.contact.email") }}{{ $t("email") }}</a
              >
            </p>
          </nav>
        </div>
      </div>
      <div class="footer_bot elementor-container">
        <p>{{ $t("footer.all") }}</p>

        <p><a href="https://beian.miit.gov.cn/" target="_block"> 冀ICP备2023005208号-1 </a>{{ $t("footer.product") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    href(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@media (max-width: 921px) {
  .one {
    width: 100%;
    div {
      flex-direction: column;
      align-items: center;
    }
  }
  .two {
    width: 40%;
    margin: 20px 0;
  }
  .three {
    width: 40%;
  }
  .introduce {
    padding: 35px 20px 35px 20px;
  }
  .footer_bot {
    flex-direction: column;
  }
}
@media (min-width: 921px) {
  .one {
    width: 35%;
  }
  .two {
    width: 20%;
  }
  .three {
    width: 20%;
  }
  .four {
    width: 20%;
  }
  .introduce {
    padding: 70px 0px 70px 0px;
  }
}
.footer {
  background: #fff000;
  color: #000;
  //   background: #979797;
  .footer_top {
    border-bottom: 1px solid #000;
    .introduce {
      transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      line-height: 2em;

      text-align: left;
      .one {
        div {
          display: flex;
        }
      }
      nav {
        display: flex;
        flex-direction: column;
      }
      img {
        // height: 100px;
        width: 200px;
        float: left;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 18px;
        margin-bottom: 20px;
      }
      a {
        padding-left: 0px;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  .footer_bot {
    padding: 30px 20px 20px 20px;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
