<template>
  <div>
    <lang-option/>
    <logo />
    <router-view />
    <foot />
  </div>
</template>
<script>
import langOption from './components/langOption.vue'
import logo from './components/logo.vue'
import foot from './components/footer.vue'
export default {
  components:{
    langOption,
    foot,
    logo
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
