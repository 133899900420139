import enLocale from "element-plus/lib/locale/lang/en";
export default {
    zhlangName: "Chinese",
    cnlangName: "Englist",
    Products: "Products",
    phoneName: "Requesting A Call",
    timeName: "Any Time You Need",
    timeValue: `9 AM - 6 PM`,
    emailName: "Sending An Email:​​",
    learnMore: "Learn More",
    download: "Download Category",
    company: 'Funaikang',
    menu: {
        home: 'Home',
        products: 'Products',
        company: 'Company',
        catalog: 'Catalog',
        contact: 'Contact Us',
    },
    home: {
        bannerh1: `Funaikang SAFETY SHOES <br> & WORK BOOTS`,
        bannerText: "Funaikang is a safety shoe manufacturing company with over 20 years of industry experience. We mainly produce safety shoes, work shoes, hiking shoes, combat boots, etc. for various working environments!",
        section1: {
            title: "ISO Certification",
            text: `Funaikang safety footwear is certified by ISO and SGS. Our
            products are of high-grade quality and are safe for your
            feet.`,
        },
        section2: {
            title: "3000+ Selection",
            text: `We have over 3000 different styles and designs of safety
            shoes and work boots. You can find a style that fits your
            style and budget!`,
        },
        section3: {
            title: "12 Months Warranty",
            text: `Our products come with a 12-month warranty against defects
            in material or craft, so you can shop confidently knowing we
            stand behind our products 100%.`,
        },
        warpData: {
            title: "Funaikang Safety Toe Shoes",
            content: `Our safety shoes are equipped with standard steel toe cap or hot-melt Gangbao toe cap/Kevlar midsole or standard steel plate, which can reach 1100N compression resistance.
            Suitable for work or travel shoes in various industries!`,
            title1: "Combat boots with low collar",
            title2: "Combat boot upper",
            title3: "Combat boot high collar",
            title4: "Outdoor hiking boots",
            title1: "Outdoor hiking boots",
            title2: "Outdoor hiking boots",
            title3: "Outdoor hiking boots",
            title4: "Waterproof/anti impact boots",
            button: "View All Safety Shoes",
        },
        // warpData1: {
        //     title: "Our Safety Shoes Services",
        //     p: `Most workplace hazards involve rolling or falling objects hitting the
        //     toes. Our safety shoes are fitted with steel or composite toe caps
        //     that offer up to 1100N compression resistance. Use Funaikang safety toe
        //     shoes to protect the feet of employees working in construction,
        //     manufacturing, and other industries where toes are at high risk of
        //     injury.`,
        //     title1: "title1",
        //     num1: "Funaikang helps you develop personalized safety shoes for your workplace safety needs. Choose from different colors, designs, and materials for a product that matches your specified requirements.",
        //     title2: "title2",
        //     num2: "Funaikang helps you develop personalized safety shoes for your workplace safety needs. Choose from different colors, designs, and materials for a product that matches your specified requirements.",
        //     title3: "title3",
        //     num3: "Funaikang helps you develop personalized safety shoes for your workplace safety needs. Choose from different colors, designs, and materials for a product that matches your specified requirements.",
        //     title4: "title4",
        //     button: "View All Safety Shoes",
        // },
        content4: {
            title: "Contact Us",
            content: "If you have any questions or concerns about our products, please feel free to contact us and we are always at your service!",
            form: {
                title: 'Get Safety Shoes Price Now',
                name: 'Name',
                email: 'Email',
                message: 'Message',
                p: `This site is protected by reCAPTCHA and the Google <a style="color: #333300;" href="https://policies.google.com/privacy"><strong>Privacy Policy</strong></a> and <strong><em><a style="color: #333300;" href="https://policies.google.com/terms">Terms of Service</a></em></strong> apply.`,
                button: 'Send'
            }


        },
        contentTitle: "Safety Shoes Company & Product Catalogue",
        contenth2: "Funaikang安全鞋公司",
        contentp1: `Our safety shoes have undergone professional design and production, making them stand out in the market.`,
        contentp2: `Download the "Funaikang" product brochure, which includes steel toe boots, Gangbao boots, etc. There is always one that suits you`,
        contentp3: `我们的PPE鞋面向40多个国家的客户
        客户群总计700多家知名公司和
        企业。`,
        contentp4: `作为一家值得信赖和知名的安全鞋公司，我们承诺
        继续为我们的客户提供专业的鞋类
        不同的工作场所。`,
    },
    products: {
        bannerText: 'Products',
        bannerPath: 'Home / Shop',
        price: 'Free quotation',
        search: 'Search',
        title: 'Safety Shoes Catalog',
        all: 'All',
        search2: 'Steel toe boots',
        search1: 'Gangbao boots'
    },
    about: {
        bannerText: "About Funaikang Safety Shoes Company",
        contentTitle: "ABOUT US",
        contenth2: "ABOUT Funaikang",
        contentp1: `Fu Naikang "safety shoes are a legitimate source (processing/OEM) manufacturer located in Hebei Handan, China. The company has 20 years of rich shoemaking experience and is a factory that integrates research and development, production, processing, and integrated source safety shoe production.`,
        contentp2: `Main Products: Main Products: Labor protection shoes, military boots, work shoes, electrical insulated shoes, electrostatic shoes, etc.`,
        contentp3: `The company is committed to the development and production of multi-functional protective shoes and related products such as safety shoes and work shoes. We have a complete development, production, and scientific quality management system. All products are produced according to national inspection standards, produced and sold by ourselves, with quality assurance and reasonable prices! The company has gained industry recognition for its integrity, strength, and product quality.`,
        contentp4: `Funaikang Shoes Co., Ltd. adheres to the philosophy of "quality comes from heart, service creates value, customer first, focus on quality, and continuous innovation." We provide more effective and high-quality services to more and more customers!`,
        contentp5: 'Official website: www.fnksafety.com',
        contentp6: `Address: No. 1, Liyi Road, Damazhuang Village, Guangping Town,    
        Guangping County, Handan City, Hebei Province`,
        contentp7: 'Contact: Liu Zhifeng',
        contentp8: 'Tel: 03108332258   +(86)13730076555',
        contentp9: "Email: funaikangsafety{'@'}yeah.net",
    },
    contact: {
        bannerText: "Contact Us",
        add: 'Our address',
        phone: 'Call for help',
        email: 'Mail us',
        title: 'CONTACT INFORMATION',
        title1: `Contact Info For Our Client<br>
        To Get In Touch`,
        from: {
            title: 'OUR SERVICE',
            title1: 'Contact',
        }
    },

    footer: {
        all: `© All rights reserved`,
        product: "Funaikang Safety Shoes",
        introduce: `Funaikang is a modern production enterprise that integrates research and development, design, production, and marketing. We have internationally advanced production lines, complete supporting facilities, and strong technical capabilities.`,
        about: {
            title: "About",
            a1: "About Funaikang",
            a2: "Manufacturing",
            a3: "Wholesale Safety Shoes",
            a4: "Contact Us",
        },
        help: {
            title: "Help Center",
            a1: "Privacy Policy",
            a2: "Terms of Use",
            a3: "Shipping, Return & Warranty",
        },
        contact: {
            title: "Contact Us",
            add: "Address:",
            phone: "Phone:",
            email: "Email:",
        },
    },
    add: "No.1 Li Yi Road, Damazhuang Village, Guangping Town, Guangping County, Handan City, Hebei Province",
    phone: "+86 13730076555",
    email: "funaikangsafety{'@'}yeah.net",
    errtext1: 'Email cannot be empty',
    errtext2: 'Please enter the correct email address',
    ...enLocale
};