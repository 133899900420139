import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: "/", redirect: "/home" },
    {
        path: "/home",
        name: "home",
        component: () =>
            import ("../views/HomeView.vue"),
    },
    {
        path: "/products",
        name: "products",
        component: () =>
            import ("../views/productsView.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import ("../views/AboutView.vue"),
    },
    {
        path: "/catalog",
        name: "catalog",
        component: () =>
            import ("../views/catalogView.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () =>
            import ("../views/contactView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;