import zhLocale from "element-plus/lib/locale/lang/zh-cn";
export default {
    zhlangName: "中文",
    cnlangName: "英文",
    Products: '产品',
    phoneName: "请求呼叫",
    timeName: "您需要的任何时间​",
    timeValue: "上午9点至下午6点",
    emailName: "发送电子邮件:​​",
    learnMore: '学习更多',
    download: '下载类别',
    company: '富耐康',
    menu: {
        home: '首页',
        products: '产品页',
        company: '公司简介',
        catalog: '目录',
        contact: '联系我们',
    },
    home: {
        bannerh1: `富耐康 安全鞋<br>&工作靴`,
        bannerText: '"富耐康"是一家拥有20年以上行业经验的安全鞋制造公司。我们主要生产各种工作环境的安全鞋、工作鞋、登山鞋、作战靴等!我们主要生产各种工作环境的安全鞋、工作鞋、登山鞋、作战靴等!',
        section1: {
            title: 'ISO Certification',
            text: `富耐康 safety footwear is certified by ISO and SGS. Our
            products are of high-grade quality and are safe for your
            feet.`,
        },
        section2: {
            title: '3000+ Selection',
            text: `We have over 3000 different styles and designs of safety
            shoes and work boots. You can find a style that fits your
            style and budget!`,
        },
        section3: {
            title: '12 Months Warranty',
            text: `Our products come with a 12-month warranty against defects
            in material or craft, so you can shop confidently knowing we
            stand behind our products 100%.`,
        },
        warpData: {
            title: '“富耐康” 安全鞋',
            content: `我们的安全鞋配有达标钢头制或热熔港宝鞋头/凯夫拉中底或达标钢板，可达到 1100N 的抗压能力。
            可用于多种行业的工作用鞋或旅游用鞋！`,
            title1: '作战靴低帮',
            title2: '作战靴中帮',
            title3: '作战靴高帮',
            title4: '户外登山靴',
            title1: '户外登山靴',
            title2: '户外登山靴',
            title3: '户外登山靴',
            title4: '防水/防砸靴',
            button: '查看所有安全鞋'
        },
        // warpData1: {
        //     title: 'Our Safety Shoes Services',
        //     p: `Most workplace hazards involve rolling or falling objects hitting the
        //     toes. Our safety shoes are fitted with steel or composite toe caps
        //     that offer up to 1100N compression resistance. Use 富耐康 safety toe
        //     shoes to protect the feet of employees working in construction,
        //     manufacturing, and other industries where toes are at high risk of
        //     injury.`,
        //     title1: 'title1',
        //     num1: '富耐康 helps you develop personalized safety shoes for your workplace safety needs. Choose from different colors, designs, and materials for a product that matches your specified requirements.',
        //     title2: 'title2',
        //     num2: '富耐康 helps you develop personalized safety shoes for your workplace safety needs. Choose from different colors, designs, and materials for a product that matches your specified requirements.',
        //     title3: 'title3',
        //     num3: '富耐康 helps you develop personalized safety shoes for your workplace safety needs. Choose from different colors, designs, and materials for a product that matches your specified requirements.',
        //     title4: 'title4',
        //     button: 'View All Safety Shoes'
        // },
        content4: {
            title: "关于我们",
            content: "如果您对我们的产品有任何疑问或担忧，请随时与我们联系，我们随时为您服务！",
            form: {
                title: '立即获取安全鞋价格',
                name: '姓名',
                email: '电子邮件',
                message: '信息',
                p: `此网站受reCAPTCHA保护，适用谷歌 <a style="color: #333300;" href="https://policies.google.com/privacy"><strong>隐私政策</strong></a> 和 <strong><em><a style="color: #333300;" href="https://policies.google.com/terms">服务条款</a></em></strong>。`,
                button: '发送'
            }


        },

        contentTitle: "安全鞋公司及产品目录",
        contenth2: "富耐康安全鞋公司",
        contentp1: `我们的安全鞋经过专业的设计与制作，使其在市场上脱颖而出。`,
        contentp2: `下载“富耐康”产品画册，其中包括钢头靴、港宝靴等总有一款适合您`,
        contentp3: `我们的PPE鞋面向40多个国家的客户
        客户群总计700多家知名公司和
        企业。`,
        contentp4: `作为一家值得信赖和知名的安全鞋公司，我们承诺
        继续为我们的客户提供专业的鞋类
        不同的工作场所。`,
    },
    products: {
        bannerText: '产品',
        bannerPath: '首页 / 产品',
        price: '免费报价',
        search: '搜索',
        title: '安全鞋目录',
        all: '全部',
        search2: '钢头靴',
        search1: '港宝靴'
    },
    about: {
        bannerText: "关于富耐康安全鞋公司",
        contentTitle: "关于我们",
        contenth2: " 关于富耐康",
        contentp1: `“富耐康”安全鞋是一家正规源头(加工/贴牌)生产厂家，坐落于中国河北—邯郸，公司有着20年丰富的制鞋经验，是一家系集于研发、生产、加工、一体化源头安全鞋生产工厂。`,
        contentp2: `主要产品:劳保鞋，军靴，工作鞋，电工绝缘鞋，静电鞋等。`,
        contentp3: `公司致力于安全鞋、工作鞋等多功能防护鞋及相关产品的开发与生产。拥有完整开发,生产、科学的质量管理体系，所有产品均按国家检查标准生产,自产自销，质量保证，价格合理!公司以诚信，实力和产品质量获得业界的认可。`,
        contentp4: `富耐康鞋业有限公司秉承着“品质源于用心，服务创造价值，一直以客户至上，专注品质，不断创新为理念。”为越来越多的客户提供更有效、更优质的服务！`,
        contentp5: '官  网: www.fnksafety.com',
        contentp6: '地  址:河北省邯郸市广平县广平镇大马庄村李义路1号',
        contentp7: '联系人：刘志峰',
        contentp8: '电  话: 03108332258   +(86)13730076555',
        contentp9: "邮箱: funaikangsafety{'@'}yeah.net",




    },

    contact: {
        bannerText: "关于我们",
        add: '我们的地址',
        phone: '寻求帮助',
        email: '给我们发邮件',
        title: '联系信息',
        title1: `联系信息供我们的客户<br>取得联系`,
        from: {
            title: '我们的服务',
            title1: '联系',
        }
    },
    footer: {
        all: `©保留所有权利`,
        product: "河北富耐康鞋业有限公司",
        introduce: `“富耐康” 是一家集研发设计、生产、营销为一体的现代化生产企业。我们拥有国际先进水平的生产流水线，配套设施完善，技术力量强大。`,
        about: {
            title: "关于",
            a1: "关于富耐康",
            a2: "制造业",
            a3: "批发安全鞋",
            a4: "关于我们",
        },
        help: {
            title: "帮助中心",
            a1: "Privacy Policy",
            a2: "Terms of Use",
            a3: "Shipping, Return & Warranty",
        },
        contact: {
            title: "关于我们",
            add: "地址:",
            phone: "电话:",
            email: "邮箱:",
        },
    },
    add: "河北省邯郸市广平县广平镇大马庄村李义路1号",
    phone: "+86 13730076555",
    email: "funaikangsafety{'@'}yeah.net",
    errtext1: '邮箱不能为空',
    errtext2: '请输入正确的邮箱地址',
    ...zhLocale
};